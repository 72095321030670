@import "_generated-font-mixins.scss";
.main {
  padding: 20px 0 0;

  &__promo {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;

    &__nav {
      position: relative;

      flex: 1 0 auto;

      min-width: 259px;
      max-width: 260px;
      min-height: 640px;
      padding: 15px;

      border: 1px solid #d8d4bc;
      border-radius: 4px;

      &__title {
        position: absolute;
        top: -12px;
        left: 10px;

        padding: 0 5px;

        font-weight: 700;

        background: #fff;
      }
    }

    &__banners {
      width: 240px;
      margin-left: 15px;
    }

    &__horizontal__banner {
      margin-top: 40px;
    }
  }

  &__ads__wrapper {
    padding-top: 40px;
  }
}

@import "_generated-font-mixins.scss";
.main {
  &__widget {
    &__gallery {
      margin-top: 10px;
      padding-left: 10px;
    }
  }

  &__horizontal__banner {
    margin: 20px 0;
  }

  &__recommends {
    overflow: hidden;

    &__title {
      padding: 20px 10px 22px;

      font-size: 18px;
      font-weight: 700;
      line-height: 21px;
      color: #000;
    }

    &__list {
      overflow-x: auto;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;

      max-width: 100%;
      margin-bottom: 17px;
      padding-bottom: 10px;

      & > div {
        margin-left: 10px;
      }
    }
  }
}

.ads {
  &__container {
    margin-bottom: 20px;
  }

  &__title {
    padding: 20px 10px 16px;

    font-size: 18px;
    font-weight: 700;
    line-height: 21px;
    color: #000;
  }
}

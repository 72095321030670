@import "_generated-font-mixins.scss";
.list {
  display: block;

  box-sizing: border-box;
  width: 100%;
  margin-top: 30px;

  font-size: 16px;
  line-height: 19px;

  &__item {
    position: relative;
    display: flex;
    align-items: center;

    & > a {
      cursor: pointer;

      display: flex;
      flex: 1 0 auto;

      padding: 13px 25px 13px 0;

      font-size: 16px;
      line-height: 19px;
      color: #333;

      border-bottom: 1px solid #e6e6e6;

      &::after {
        @include icon-arrow-right;

        position: absolute;
        top: 16px;
        right: 24px;

        font-size: 20px;
        color: #999;
      }
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 25px;
    height: 45px;
    margin: 0 10px 0 15px;

    &::before {
      font-size: 24px;
      color: #999;
    }

    &__more::before {
      @include icon-my-ads;

      color: #999;
    }
  }

  &__hide {
    display: inline-block;
    padding: 13px 25px 13px 48px;
    color: #0058c5;

    &::after {
      @include icon-arrow-up;

      margin-left: 5px;
      font-size: 18px;
      color: #0058c5;
      vertical-align: middle;
    }
  }
}
